import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title='Treelative' link='https://treelative.com' imgLink='/treelative_xgbbqk.jpg' centerImg bg='linear-gradient(to right, #D585FF 0%, #00FFEE 100%)' date='Jul 2019 - Present' mdxType="ProjectCard" />
    <ProjectCard title='Donkey' link='https://donkey.arun99.dev' imgLink='/donkey_s4kqhk.jpg' centerImg bg='linear-gradient(to right, #009245 0%, #FCEE21 100%)' date='Mar 2021 - Apr 2021' mdxType="ProjectCard" />
    <ProjectCard title='Gallery' link='https://gallery.arun99.dev' imgLink='/gallery_v6rxdc.jpg' bg='linear-gradient(to right, #662D8C 0%, #ED1E79 100%)' date='Dec 2020 - Jan 2021' mdxType="ProjectCard" />
    <ProjectCard title='Netflix' link='https://netflix-99.netlify.app' imgLink='/netflix_zijqtm.jpg' bg='linear-gradient(to right, #D4145A 0%, #FBB03B 100%)' date='Nov 2020 - Jan 2021' mdxType="ProjectCard" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      