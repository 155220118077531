import * as React from 'react'
import { Parallax } from '@react-spring/parallax'

import Hero from './components/Hero'
import About from './components/About'
import Layout from './components/Layout'
import Contact from './components/Contact'
import Projects from './components/Projects'

const App = () => {
  return (
    <Layout>
      <Parallax pages={6}>
        <Hero offset={0} factor={1} />
        <Projects offset={1} factor={2} />
        <About offset={3} factor={2.5} />
        <Contact offset={5} factor={1} />
      </Parallax>
    </Layout>
  )
}

export default App
