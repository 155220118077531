import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`About`}</h3>
    <blockquote>
      <h6 parentName="blockquote">{`CS Student | Full Stack Developer | UI/UX/Graphics Designer | Photographer | Photo/Video Editor | Ethical Hacker | Hackintosher | Gamer`}</h6>
    </blockquote>
    <ul>
      <li parentName="ul">{`Self-taught Full Stack Developer with a strong passion for web development and currently studying Computer Science.`}</li>
      <li parentName="ul">{`I have honed my skills through independent learning and hands-on projects, allowing me to develop a deep understanding of both front-end and back-end.`}</li>
      <li parentName="ul">{`I am particularly passionate about UI/UX/Graphics design and photography, which allows me to combine my technical skills with my creative eye to create visually stunning and user-friendly interfaces.`}</li>
      <li parentName="ul">{`I am able to work independently or in a team, with a proactive attitude and strong problem-solving skills.`}</li>
      <li parentName="ul">{`With a proactive mindset and a dedication to continuous learning, I am committed to creating innovative and efficient web solutions.`}</li>
      <li parentName="ul">{`Crazy fan of Marvel Cinematic Universe.`}</li>
    </ul>
    <h3>{`Education`}</h3>
    <blockquote>
      <h6 parentName="blockquote">{`Secondary School`}</h6>
      <ul parentName="blockquote">
        <li parentName="ul">{`@ `}<a parentName="li" {...{
            "href": "https://www.istitutocomprensivorapallo.edu.it/"
          }}>{`Istituto Comprensivo Rapallo`}</a>{` (ex Giustiniani), Sep 2010 - Jun 2014`}</li>
      </ul>
    </blockquote>
    <h6>{`High School for Scientific Studies - Applied Science/s`}</h6>
    <ul>
      <li parentName="ul">{`@ `}<a parentName="li" {...{
          "href": "https://www.liceti.edu.it/pagine/liceo-scientifico-scienze-applicate"
        }}>{`Istituto Istruzione Superiore Statale F.Liceti`}</a>{`, Sep 2014 - Jun 2019`}</li>
    </ul>
    <h6>{`Computer Engineering`}</h6>
    <ul>
      <li parentName="ul">{`@ `}<a parentName="li" {...{
          "href": "https://corsi.unige.it/corsi/8719/"
        }}>{`Università Degli Studi Di Genova Scuola Politecnica`}</a>{`, Sep 2019 - Jun 2020`}</li>
    </ul>
    <h6>{`Computer Science`}</h6>
    <ul>
      <li parentName="ul">{`@ `}<a parentName="li" {...{
          "href": "https://corsi.unige.it/corsi/8759/"
        }}>{`Università Degli Studi Di Genova`}</a>{`, Sep 2020 - Present`}</li>
    </ul>
    <h3>{`Skills`}</h3>
    <blockquote>
      <h6 parentName="blockquote">{`Programming Languages:`}</h6>
      <ul parentName="blockquote">
        <li parentName="ul">{`JavaScript, C, C++, Java, oCaml, Python`}</li>
      </ul>
    </blockquote>
    <h6>{`Frameworks:`}</h6>
    <ul>
      <li parentName="ul">{`Node.js, Express.js, React, Gatsby,js, Next.js, Sveltekit`}</li>
    </ul>
    <h6>{`Databases:`}</h6>
    <ul>
      <li parentName="ul">{`MongoDB, MySQL, PostgreSQL, Dgraph`}</li>
    </ul>
    <h6>{`Dev Tools:`}</h6>
    <ul>
      <li parentName="ul">{`Visual Studio Code, Github, Docker, Netlify, ZSH`}</li>
    </ul>
    <h6>{`Design Tools:`}</h6>
    <ul>
      <li parentName="ul">{`Figma, Adobe Photoshop, Lightroom, Adobe After Effects, Final Cut Pro`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      