/** @jsx jsx */
import { Box, useColorMode, jsx } from 'theme-ui'

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === 'dark'
  const toggleColorMode = () => { setColorMode(isDark ? 'light' : 'dark') }

  return (
    <Box as='footer' variant='footer'>
      <button
        sx={{ variant: 'buttons.toggle', fontWeight: 'semibold', display: 'block', mx: 'auto', mb: 3 }}
        onClick={toggleColorMode}
        type='button'
        aria-label='Toggle dark mode'
      >
        {isDark ? 'Light' : 'Dark'}
      </button>
      &copy; {new Date().getFullYear()}. All rights reserved by Arun.
    </Box>
  )
}

export default Footer
