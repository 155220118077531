/** @jsx jsx */
import { jsx } from 'theme-ui'
import { tailwind } from '@theme-ui/presets'

const AboutCard = ({ children }) => {
  return (
    <div
      sx={{
        width: '100%',
        boxShadow: 'lg',
        borderRadius: 'lg',
        px: 3,
        py: 1,
        bg: tailwind.colors.gray[9],
        letterSpacing: 'wide',
        transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important',
      }}
    >
      <div sx={{ opacity: 0.85, textShadow: '0 2px 10px rgba(0, 0, 0, 0.3)' }}>{children}</div>
    </div>
  )
}

export default AboutCard
